//Crypt Core ================================================================================================================================================
var crypt = (function () {
	'use strict';
	//Public functions ------------------------------------------------------------------------------------------->
	var jsonPCallback = {};

	var publicMembers = {
		jsonPCallback: jsonPCallback,
		setJSONPCallback: setJSONPCallback,
		jsonPRequest: jsonPRequest,
		hashCode: hashCode,
		objectExtend: objectExtend,
		ajaxRequest: ajaxRequest,
		jsonParse: jsonParse,
		cookiesEnabled: cookiesEnabled,
		setCookie: setCookie,
		getCookie: getCookie,
		removeCookie: removeCookie,
		removeCryptCookies: removeCryptCookies,
		daysToNumber: daysToNumber,
		minutesToNumber: minutesToNumber,
	};

	return publicMembers;

	// ------------------------------------------------------------------------------------------->

	function setJSONPCallback(file, callback) {
		jsonPCallback[file] = function (result) {
			if (callback) {
				callback(result);
				window.setTimeout(() => {
					// delete after short timeout as we want to make sure callback function has fully executed before we delete the reference
					delete jsonPCallback[file];
				}, 500);
			}
		};
	}

	function jsonPRequest(url, name, callback) {
		if (!url || !name || !callback) {
			return;
		}

		// insert callback name into url
		url = url.split('?');
		url[0] += `?callback=${name}`;
		url = url.join('&');

		// set callback function to jsonp object
		setJSONPCallback(name, callback);

		// append script to head then remove it
		const head = document.head;
		const script = document.createElement('script');

		script.setAttribute('src', url);
		head.appendChild(script);
		head.removeChild(script);
	}

	function hashCode(str) {
		var hash = 0,
			i,
			chr,
			len;
		if (str.length === 0) {
			return hash;
		}
		for (i = 0, len = str.length; i < len; i++) {
			chr = str.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	function objectExtend(obj1, obj2) {
		for (var attrname in obj2) {
			obj1[attrname] = obj2[attrname];
		}
	}

	//Ajax no dependencies required
	function ajaxRequest(file, success, error) {
		var xmlhttp;

		if (window.XMLHttpRequest) {
			// code for IE7+, Firefox, Chrome, Opera, Safari
			xmlhttp = new XMLHttpRequest();
		} else {
			// code for IE6, IE5
			xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
		}

		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState == 4) {
				if (xmlhttp.status == 200) {
					success(xmlhttp.responseText, xmlhttp.status);
				} else {
					error(xmlhttp.responseText, xmlhttp.status);
				}
			}
		};

		xmlhttp.open('GET', file, true);
		xmlhttp.send();
	}

	function jsonParse(data) {
		if (!data) {
			return;
		}
		return window.JSON && window.JSON.parse
			? window.JSON.parse(data)
			: new Function('return ' + data)();
	}

	// Cookie utilities
	function cookiesEnabled() {
		var cookieEnabled = navigator.cookieEnabled ? true : false;
		if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
			document.cookie = 'testcookie';
			cookieEnabled =
				document.cookie.indexOf('testcookie') !== -1 ? true : false;
		}
		return cookieEnabled ? true : false;
	}

	function setCookie(name, value, days) {
		var expires;
		var secure = location.protocol === 'https:' ? '; secure' : '';

		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toGMTString();
		} else {
			expires = '';
		}
		document.cookie = name + '=' + value + expires + '; path=/' + secure;
	}

	function parseCookie(name, cookieString) {
		var nameEQ = name + '=';
		var ca = cookieString.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length, c.length);
			}
		}
		return null;
	}

	function getCookie(name) {
		var cookies = document.cookie;
		return parseCookie(name, cookies);
	}

	function removeCookie(name) {
		setCookie(name, '', -1);
	}

	function removeCryptCookies() {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var spcook = cookies[i].split('=');
			if (spcook.indexOf('dbk-crypt') > -1) {
				removeCookie(spcook[0]);
			}
		}
	}

	function daysToNumber(dayCount) {
		return 1000 * 60 * 60 * 24 * Number(dayCount);
	}

	function minutesToNumber(minuteCount) {
		return 1000 * 60 * Number(minuteCount);
	}
})();

//Crypt Hash ================================================================================================================================================
(function (core) {
	'use strict';

	//Configure module ------------------------------------------------------------------------------------------->
	//Define configuration options. Defined outside of object creation so obfuscation can properly hide values.
	/*<!--config-crypt-start-->*/
	var platform = 'dbko';
	/*<!--config-crypt-end-->*/

	function GetConfig(platform) {
		if (platform === 'dbkfm') {
			this.platform = 'dbkfm';
			this.productName = 'DBk4FM';
			this.version = '10.57';
			this.activationURL =
				'http://dayback.io/web-calendar/get-order-details.php';
			this.getUserLimit = function (quantity, productName) {
				return productName.split('-').splice(-1, 1)[0];
			};
			this.selfDestructEnabled = true;
		} else {
			this.platform = 'dbko';
			this.productName = 'DBkOnline';
			this.dbkoProductName = 'dayback-calendar';
			this.dbkoOrderPrefix = 'DAY';
			this.version = '2.05';
			this.activationURL = 'php/activation/get-order-details.php';
			this.dbkoActivationURL =
				'php/activation/dbko-subscription-details.php';
			this.getUserLimit = function (quantity, productName) {
				return quantity || productName.split('-').splice(-1, 1)[0];
			};
			this.selfDestructEnabled = false;
		}

		this.hashAccepted = false;
		this.hashVerified = false;
		this.cookieName = 'dbk-crypt-hash';
		this.cookieExpires = '365';
		this.trialExpires = '30';
		this.preventExpire = false;
		this.updateExpires = '365';
		this.userLimit = 5;
		this.firstRunString = 'new';
		this.clearElement = 'app-container';
		this.cookiesEnabled = core.cookiesEnabled();
		this.getUserPath = function () {
			var path = window.location.pathname;
			path = path.split('/');
			path.splice(path.length - 2, 2);
			path = path.join('/') + '/';
			return path;
		};
		this.defaultString = function () {
			var now = new Date();
			now.setHours(0, 0, 0, 0);
			now = now.getTime();
			var expires = now + core.daysToNumber(this.trialExpires);
			var restoreDirectory = createDirectoryName(this.version);
			var upgradeDirectory = 'get-update';
			var type = 'trial';

			//Levels - 0: Free after demo, 1: Free before demo, 2: Demo, 3: Pro
			return (
				'version=' +
				this.version +
				'&level=2' +
				'&restoreDirectory=' +
				restoreDirectory +
				'&upgradeDirectory=' +
				upgradeDirectory +
				'&hashDate=' +
				now +
				'&expiresDate=' +
				expires +
				'&type=' +
				type +
				'&userLimit=' +
				this.userLimit
			);
		};
	}

	//Set our global config var and run self destruct check
	var config = new GetConfig(platform);

	if (config.selfDestructEnabled) {
		selfDestruct();
	}

	//Self destruct function to make sure someone isn't interfering with execution of verifying the hash
	function selfDestruct() {
		window.setTimeout(function () {
			if (!config.hashAccepted) {
				var hash = core.getCookie(config.cookieName);
				var newVersion = config.version;
				var oldVersion = getHashProperty(hash, 'version');
				var updateMessage =
					oldVersion &&
					parseFloat(newVersion, 10) > parseFloat(oldVersion, 10)
						? '<h1>FileMaker File Changes Required:</h1><p>You have upgraded from version ' +
						  oldVersion +
						  '. There were a few FileMaker changes required between that version and version ' +
						  newVersion +
						  ". Please look at the <a href='http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory' target='_blank'>version history</a> and apply the appropriate changes for each version in between your current upgrade.</p>"
						: '';
				var instructions = updateMessage
					? updateMessage +
					  '<p style="font-weight: bold;">If you did not just update DayBack please see below for other possible reasons that you are seeing this message</p>' +
					  "<p>• If this is because you're integrating DayBack with your own files, then this is normal. Keep following the integration instructions and the calendar will appear again here soon.</p>" +
					  '<p>• If you\'re not in the midst of integrating DayBack then please checkout the troubleshooting steps here to see what might be wrong: <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.Troubleshooting" target="_blank">Troubleshooting Guide</a></p>'
					: '<h1>Action Required:</h1>' +
					  "<p>FileMaker has not received the information it expected from it's DayBack scripts.</p>" +
					  '<p>• If this is because you have just updated DayBack to a new version then there may be a FileMaker script update necessary for things to work properly. Check the <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.VersionHistory" target="_blank">version history</a> for update instructions.</p>' +
					  "<p>• If this is because you're integrating DayBack with your own files, then this is normal. Keep following the integration instructions and the calendar will appear again here soon.</p>" +
					  '<p>• If you\'re not in the midst of integrating DayBack then please checkout the troubleshooting steps here to see what might be wrong: <a href="http://www.seedcode.com/pmwiki/index.php?n=DayBackForFileMaker.Troubleshooting" target="_blank">Troubleshooting Guide</a></p>';
				destroy(
					"<div style='text-align: left; margin: 100px; color: gray;'>" +
						instructions +
						'<p>Need more help? <a href="http://www.seedcode.com/contact/" target="_blank">Get in touch</a>; we\'re here to help.</p>' +
						'</div>'
				);
			}
		}, 6000);
	}

	//Public functions ------------------------------------------------------------------------------------------->
	//Read hash and verify legitimacy
	var runVerifyHash = function (hash, callBacks, platform, verifyOnly) {
		//callbacks is an object containing:
		//successCallback, expiredCallback, trialCallback, updateCallback, userLicenseCallback
		var verifiedHash = verifyHash(hash, callBacks, platform, verifyOnly);
		return verifiedHash;
	};
	var runVerifyActivation = function (activationObj, callbacks, verifyOnly) {
		return verifyActivation(activationObj, callbacks, verifyOnly);
	};

	var runActivation = function (
		orderNumber,
		email,
		name,
		successCallback,
		errorCallback
	) {
		var activated = activate(
			orderNumber,
			orderNumber,
			email,
			name,
			'order',
			successCallback,
			errorCallback
		);
	};
	var runGetHashProperty = function (hash, property) {
		//Available properties are: version, level, hashDate, expiresDate, type, subscriptionURL
		return getHashProperty(hash, property);
	};
	var runUpdateHashValue = function (hash, property, value) {
		return updateHashValue(hash, property, value);
	};
	var clearHashCache = function () {
		core.removeCookie(config.cookieName);
	};

	var runResetUserLicense = function (hash) {
		var reset = resetUserLicense(hash);
		return reset;
	};

	var createHashToken = function (prefix, str) {
		var resultString;
		var breakPoint;
		var ends = '|' + str.length + '|';
		resultString = core.hashCode(ends + str + ends).toString();
		breakPoint = Math.ceil(resultString.length / 2);
		resultString =
			resultString.slice(0, breakPoint) +
			'-' +
			resultString.slice(breakPoint);
		return prefix + resultString + 'tk';
	};

	var createTrialExpirationDate = function () {
		var now = moment().hours(0).minutes(0).seconds(0);
		var expires = now.add('days', config.trialExpires);
		return expires.format();
	};
	var getTrialString = function () {
		return 'trialing';
	};

	function hasValidSubscriptionState(state) {
		return (
			state === 'active' ||
			state === 'past_due' ||
			state === 'soft_failure'
		);
	}

	function hasOverdueState(state) {
		return state === 'past_due';
	}

	//Assign our public method
	var publicMembers = {
		runVerifyHash: runVerifyHash,
		runVerifyActivation: runVerifyActivation,
		runVerifySubscription: verifySubscription,
		runActivation: runActivation,
		runGetHashProperty: runGetHashProperty,
		runUpdateHashValue: runUpdateHashValue,
		clearHashCache: clearHashCache,
		runResetUserLicense: runResetUserLicense,
		createHashToken: createHashToken,
		stringToHash: stringToHash,
		hashToString: hashToString,
		createTrialExpirationDate: createTrialExpirationDate,
		getTrialString: getTrialString,
		hasValidSubscriptionState: hasValidSubscriptionState,
		hasOverdueState: hasOverdueState,
	};

	//Add public members to crypt core module
	core.objectExtend(core, publicMembers);

	return publicMembers;

	//Private functions ------------------------------------------------------------------------------------------->

	//Utility function to decode base62
	function base62decode(a, b, c, d) {
		for (
			b = c = (a === (/\W|_|^$/.test((a += '')) || a)) - 1;
			(d = a.charCodeAt(c++));

		)
			b = b * 62 + d - [0, 48, 29, 87][d >> 5];
		return b;
	}
	//Utility function to encode base62
	function base62encode(a, b, c) {
		for (
			a = a !== +a || a % 1 ? -1 : a, b = '';
			a >= 0;
			a = Math.floor(a / 62) || -1
		)
			b =
				String.fromCharCode(
					((c = a % 62) > 9 ? (c > 35 ? 29 : 87) : 48) + c
				) + b;
		return b;
	}
	//Create hash from string
	function stringToHash(text) {
		var bytes = [];
		for (var i = 0; i < text.length; i++) {
			bytes.push(base62encode(text.charCodeAt(i)));
		}
		return bytes.join('');
	}

	function getHashProperty(hash, property) {
		return getDecodedKey(hashToString(hash), property);
	}

	function updateHashValue(hash, property, value) {
		var hashString = hashToString(hash);
		var hashArray = hashString.split('&');
		var matched;

		for (var i = 0; i < hashArray.length; i++) {
			var hashItem = hashArray[i].split('=');
			var hashProperty = hashItem[0];
			var hashValue = hashItem[1];

			if (hashProperty === property) {
				hashArray[i] = property + '=' + value;
				matched = true;
				break;
			}
		}
		//If this property isn't already in our hash let's add it
		if (!matched) {
			hashArray.push(property + '=' + value);
		}
		//Join the key value pairs into one string
		hashString = hashArray.join('&');

		return stringToHash(hashString);
	}

	//Create string from hash
	function hashToString(text) {
		var bytes = [];
		var lastChar = 0;

		for (var i = 0; i < text.length; i++) {
			if (isNaN(text[i]) || i - lastChar >= 1) {
				bytes.push(text.substr(lastChar, i - lastChar + 1));
				lastChar = i + 1;
			}
		}
		for (var i = 0; i < bytes.length; i++) {
			bytes[i] = String.fromCharCode(base62decode(bytes[i]));
		}

		return bytes.join('');
	}

	//Parse decoded hash keys
	function getDecodedKey(decodedString, key) {
		var targetKeyValue = [],
			keyValues = decodedString.split('&');

		for (var i = 0; i < keyValues.length; i++) {
			targetKeyValue = keyValues[i].split('=');
			if (key === targetKeyValue[0]) {
				return targetKeyValue[1];
			}
		}
	}

	function createDirectoryName(version) {
		return version + '-' + stringToHash(version);
	}

	function activationConfirmed(confirmation, email, name, callback) {
		//Levels - 0: Free after demo, 1: Free before demo, 2: Demo, 3: Pro
		//Type - order, subscription
		var hash;
		var now = new Date();
		now.setHours(0, 0, 0, 0);
		now = now.getTime();
		var orderDate;
		var expires;
		var type = confirmation.type;
		var level = 3;
		var reference = confirmation.reference;
		var orderNumber = confirmation.orderNumber;
		var productDisplay = confirmation.orderItem.productDisplay;
		var productName = confirmation.orderItem.productName;
		var subscriptionID = ''; //Setting an empty string because this get's converted to a string and we don't want undefined in our string if this doesn't get set
		var subscriptionURL = ''; //Setting an empty string because this get's converted to a string and we don't want undefined in our string if this doesn't get set
		var restoreDirectory = createDirectoryName(config.version);
		var upgradeDirectory = 'get-update';
		var cutoff;
		var quantity;
		var userLimit;

		if (type === 'order') {
			orderDate = confirmation.orderDate
				.split('T')[0]
				.replace(/Z/g, '')
				.split('-');
			orderDate = new Date(
				orderDate[0],
				orderDate[1] - 1,
				orderDate[2]
			).getTime();
			expires = orderDate + core.daysToNumber(config.updateExpires);
			cutoff = expires + core.daysToNumber(15);
		} else if (type === 'subscription') {
			if (confirmation.nextPeriodDate.length) {
				expires = confirmation.nextPeriodDate
					.replace(/Z/g, '')
					.split('-');
				expires = new Date(
					expires[0],
					expires[1] - 1,
					expires[2]
				).getTime();
				cutoff = expires + core.daysToNumber(5);
			} else if (confirmation.end) {
				expires = confirmation.end.replace(/Z/g, '').split('-');
				expires = new Date(
					expires[0],
					expires[1] - 1,
					expires[2]
				).getTime();
				cutoff = expires + core.daysToNumber(5);
			}
			quantity = confirmation.orderItem.quantity;
			subscriptionID = confirmation.subscriptionID || '';
			subscriptionURL = confirmation.customerUrl || '';
		}

		userLimit = config.getUserLimit(quantity, productName);

		hash = stringToHash(
			'version=' +
				config.version +
				'&level=' +
				level +
				'&productDisplay=' +
				productDisplay +
				'&productName=' +
				productName +
				'&restoreDirectory=' +
				restoreDirectory +
				'&upgradeDirectory=' +
				upgradeDirectory +
				'&hashDate=' +
				now +
				'&orderDate=' +
				orderDate +
				'&expiresDate=' +
				expires +
				'&cutoffDate=' +
				cutoff +
				'&userLimit=' +
				userLimit +
				'&type=' +
				type +
				'&reference=' +
				reference +
				'&subscriptionID=' +
				subscriptionID +
				'&subscriptionURL=' +
				subscriptionURL +
				'&orderNumber=' +
				orderNumber +
				'&email=' +
				email +
				'&name=' +
				name
		);

		if (callback) {
			callback(hash);
		}
	}

	function activate(
		referenceID,
		orderNumber,
		email,
		name,
		type,
		successCallback,
		errorCallback
	) {
		var orderData;

		getReferenceData(referenceID, email, type);
		function getReferenceData(referenceID, email, type) {
			var activationURL;
			var productName;
			var isDBKOProduct;

			//Determine if this uses the new subscription only order system
			if (referenceID.slice(0, 3) === config.dbkoOrderPrefix) {
				isDBKOProduct = true;
				activationURL = config.dbkoActivationURL;
				productName = config.dbkoProductName;
			} else {
				isDBKOProduct = false;
				activationURL = config.activationURL;
				productName = config.productName;
			}
			core.ajaxRequest(
				activationURL +
					'?referenceID=' +
					encodeURIComponent(referenceID) +
					'&email=' +
					encodeURIComponent(email) +
					'&product=' +
					encodeURIComponent(productName) +
					'&type=' +
					encodeURIComponent(type),
				//Success
				function (data, status) {
					data = core.jsonParse(data);
					var status = data.status;
					if (
						data.orderItem &&
						data.orderItem.subscriptionReference
					) {
						orderData = data;
						getReferenceData(
							data.orderItem.subscriptionReference,
							email,
							'subscription'
						);
					} else if (status === 'completed' || status === 'active') {
						if (orderData || isDBKOProduct) {
							data.orderNumber = referenceID;

							if (!isDBKOProduct) {
								data.orderItem = {
									quantity: orderData.orderItem.quantity,
									productDisplay:
										orderData.orderItem.productDisplay,
									productName:
										orderData.orderItem.productName,
								};
							}
						} else {
							data.orderNumber = data.reference;
						}
						activationConfirmed(data, email, name, successCallback);
					} else {
						if (errorCallback) {
							errorCallback(data);
						}
					}
				},
				//Error
				function (data, status) {
					if (errorCallback) {
						errorCallback();
					}
				}
			);
		}
	}
	function verifySubscription(
		hash,
		referenceID,
		subscriptionID,
		successCallback,
		errorCallback
	) {
		var isDBKOProduct;
		var activationURL;
		//Determine if this uses the new subscription only order system
		if (referenceID.slice(0, 3) === config.dbkoOrderPrefix) {
			isDBKOProduct = true;
			activationURL = config.dbkoActivationURL;
		} else {
			isDBKOProduct = false;
			activationURL = config.activationURL;
		}

		core.ajaxRequest(
			activationURL +
				'?referenceID=' +
				encodeURIComponent(referenceID) +
				'&subscriptionID=' +
				subscriptionID +
				'&type=subscription&action=verify',
			//Success
			function (data, status) {
				data = core.jsonParse(data);
				var expires,
					cutoff,
					status = data.status;

				var now = new Date();
				now.setHours(0, 0, 0, 0);
				now = now.getTime();

				if (status === 'active') {
					if (data.nextPeriodDate.length) {
						expires = data.nextPeriodDate
							.replace(/Z/g, '')
							.split('-');
						expires = new Date(
							expires[0],
							expires[1] - 1,
							expires[2]
						).getTime();
						cutoff = expires + core.daysToNumber(5);
					} else if (data.end) {
						expires = data.end.replace(/Z/g, '').split('-');
						expires = new Date(
							expires[0],
							expires[1] - 1,
							expires[2]
						).getTime();
						cutoff = expires + core.daysToNumber(5);
					}
					if (now < expires + core.daysToNumber(1)) {
						config.preventExpire = true;
					}
					if (now < cutoff) {
						hash = updateHashValue(hash, 'expiresDate', expires);
						hash = updateHashValue(hash, 'cutoffDate', cutoff);
						successCallback(hash);
					} else {
						errorCallback(false);
					}
				} else {
					errorCallback(false);
				}
			},
			//Error
			function (data, status) {
				if (errorCallback) {
					errorCallback(status);
				}
			}
		);
	}

	//New cart activation (uses object in datastore rather than hash)
	function verifyActivation(
		activation,
		manuallyDeactivated,
		callbacks,
		verifyOnly
	) {
		//callbacks include: successCallback, expiredCallback, trialCallback, updateCallback, userLicenseCallback
		var expiresHoursPadding = 24;
		var type =
			activation.state === getTrialString() || !activation.subscriptionID
				? 'trial'
				: 'subscription';
		var firstRun = activation.trialStart;
		var expiresDate = new Date(activation.currentPeriodEndsAt).getTime();

		var waitToExpire = type === 'subscription' ? 3000 : 0;

		var now = new Date();

		// If it's a trial we just want to compare the date and not the time
		if (activation.state === getTrialString()) {
			now.setHours(0, 0, 0, 0);
		}
		now = now.getTime();

		if (
			!manuallyDeactivated &&
			activation.state === getTrialString() &&
			now < expiresDate
		) {
			if (callbacks.successCallback && firstRun) {
				callbacks.successCallback(activation, firstRun);
			} else if (callbacks.trialCallback) {
				trialMessage(callbacks.trialCallback);
			}
		} else if (!manuallyDeactivated && hasOverdueState(activation.state)) {
			callbacks.overdueCallback(activation.firstRun);
		} else if (
			!manuallyDeactivated &&
			hasValidSubscriptionState(activation.state)
		) {
			// callbacks.successCallback(activation, firstRun);
		} else {
			clearForExpired(
				config,
				type,
				waitToExpire,
				manuallyDeactivated,
				callbacks
			);
		}
	}

	//Verify if the hash is good and act accordingly
	function verifyHash(hash, callbacks, platform, verifyOnly) {
		//callbacks include: successCallback, expiredCallback, trialCallback, updateCallback, userLicenseCallback

		var now = new Date();
		now.setHours(0, 0, 0, 0);
		now = now.getTime();

		var firstRun,
			version,
			hashDate,
			expiresDate,
			level,
			type,
			reference,
			productName,
			userLimit,
			users,
			upgradeDirectory,
			restoreDirectory;

		var verifyConfig;

		if (platform && verifyOnly) {
			verifyConfig = new GetConfig(platform);
		} else {
			verifyConfig = config;
		}
		//If the hash equals our first launch string clear cookies and set the hash to our default value
		if (hash === verifyConfig.firstRunString) {
			core.removeCookie(verifyConfig.cookieName);
			hash = stringToHash(verifyConfig.defaultString());
			firstRun = true;
		}
		//Check for a valid hash by getting a required property. If a valid hash is not found create the default hash in an expired state
		else if (
			!getHashProperty(hash, 'hashDate') ||
			now < getHashProperty(hash, 'hashDate')
		) {
			hash =
				core.getCookie(verifyConfig.cookieName) ||
				stringToHash(verifyConfig.defaultString());
			hash = updateHashValue(
				hash,
				'expiresDate',
				now - core.daysToNumber(1)
			);
		}

		//Get hash properties
		version = Number(getHashProperty(hash, 'version'));
		hashDate = Number(getHashProperty(hash, 'hashDate'));
		expiresDate = Number(getHashProperty(hash, 'expiresDate'));
		level = Number(getHashProperty(hash, 'level'));
		type = getHashProperty(hash, 'type');
		reference = getHashProperty(hash, 'reference');
		productName = getHashProperty(hash, 'productName');
		userLimit = getHashProperty(hash, 'userLimit');
		users = getHashProperty(hash, 'users');
		upgradeDirectory = getHashProperty(hash, 'upgradeDirectory');
		restoreDirectory = getHashProperty(hash, 'restoreDirectory');

		//Set accepted shared value to true so our self destruct timer doesn't fire
		config.hashAccepted = true;
		//Subscription has expired
		if (
			(type === 'subscription' &&
				now >= expiresDate + core.daysToNumber(5)) ||
			(type === 'trial' && now >= expiresDate)
		) {
			//If we are only verifying a valid subscription / trial run our callback without expiring
			if (config.hashVerified && verifyOnly) {
				if (callbacks.expiredCallback) {
					callbacks.expiredCallback(type);
				}
				return;
			}

			//We have expired clear calendar element and run expired callback
			var waitToExpire = type === 'subscription' ? 3000 : 0;
			clearForExpired(verifyConfig, type, waitToExpire, null, callbacks);
		}
		//We have not expired
		else {
			//If we are only verifying this hash (most likely for sharing in DBKFM) then we just want to return the hash with callback
			if (verifyConfig.hashVerified && verifyOnly) {
				if (callbacks.successCallback) {
					callbacks.successCallback(hash);
				}
				return;
			}
			//Record that we have verified the hash and it's valid
			verifyConfig.hashVerified = true;
			//If we have purchased let's check user limit
			if (productName && verifyConfig.platform === 'dbkfm') {
				hash = verifyUserLicense(
					hash,
					productName,
					users,
					callbacks.userLicenseCallback
				);
			}
			//If the version changed that means an update happened and we need to update the hash to reflect the new version
			if (Number(version) !== Number(verifyConfig.version)) {
				//Update hash
				hash = updateHashValue(hash, 'previousVersion', version);
				hash = updateHashValue(hash, 'version', verifyConfig.version);
				hash = updateHashValue(
					hash,
					'restoreDirectory',
					createDirectoryName(verifyConfig.version)
				);
				hash = updateHashValue(
					hash,
					'upgradeDirectory',
					stringToHash('latest')
				);

				//Run callback
				callbacks.updateCallback();
			}
			//If the date expired and the type is order we need to restrict upgrades to the current version.
			else if (
				type === 'order' &&
				now >= expiresDate &&
				upgradeDirectory !== restoreDirectory
			) {
				hash = updateHashValue(
					hash,
					'upgradeDirectory',
					restoreDirectory
				);
			}
			core.setCookie(
				verifyConfig.cookieName,
				hash,
				verifyConfig.cookieExpires
			);
			if (callbacks.successCallback) {
				callbacks.successCallback(hash, firstRun);
			}

			if (type === 'trial' && !firstRun) {
				trialMessage(callbacks.trialCallback);
			}
		}
		return hash;
	}

	function clearForExpired(
		config,
		type,
		delay,
		manuallyDeactivated,
		callbacks
	) {
		//We have expired clear calendar element and run expired callback
		window.setTimeout(function () {
			var clearElement =
				document.getElementById(config.clearElement) || document.body;
			if (!config.preventExpire || manuallyDeactivated) {
				clearElement.style.opacity = 0;
				window.setTimeout(function () {
					clearElement.innerHTML = '';
				}, 3000);
				if (manuallyDeactivated && callbacks.deactivatedCallback) {
					callbacks.deactivatedCallback();
				} else if (callbacks.expiredCallback) {
					callbacks.expiredCallback(type);
				}
			}
		}, delay);
	}

	//Trial mode message
	function trialMessage(callback) {
		return; // Disabled as we now show expiration warnings in menus
	}

	//Verifies user count matches license level and sets updated user counts
	function verifyUserLicense(hash, productName, users, callback) {
		var userLimit = config.getUserLimit(null, productName);
		if (userLimit !== 'N') {
			userLimit = Number(userLimit);
			users = users ? users.split('|') : [];
			var path = config.getUserPath();

			if (users.indexOf(config.getUserPath()) === -1) {
				users.push(config.getUserPath());
			}
			if (users.length > userLimit) {
				destroy(null);
				callback();
			} else {
				hash = updateHashValue(hash, 'users', users.join('|'));
			}
		}
		return hash;
	}

	//Reset user license count
	function resetUserLicense(hash, callback) {
		var users = [config.getUserPath()];
		return updateHashValue(hash, 'users', users.join('|'));
	}

	//Destroys the body element as to kill the product. Used for expired licenses
	function destroy(message) {
		var clearElement = document.body;
		message = message ? message : '';
		clearElement.innerHTML = message;
	}
})(crypt);
